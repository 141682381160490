import React, { useState, useEffect, useRef } from "react";
import QRCode from "react-qr-code";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  Button,
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { uuidv7 } from "uuidv7";
import axios from "axios";
import { db, auth } from "../../firebase";
import { signInAnonymously } from "firebase/auth";
import {
  collection,
  getDocs,
  onSnapshot,
  addDoc,
  deleteDoc,
  DocumentData,
} from "firebase/firestore";
import { decrypt } from "../../services/aes/aes";

interface DocumentType extends DocumentData {
  id: string;
  website: string;
  username: string;
  password: string;
  // Add other fields as needed
}
function QRCodePage() {
  const [randomString, setRandomString] = useState("");
  const [ivstring, setIVString] = useState("");
  const [keystring, setKeyString] = useState("");
  const [topic, setTopic] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [messages, setMessages] = useState<any[]>([]);
  const [rows, setRows] = React.useState<DocumentType[]>([]);
  const docs = useRef<DocumentType[]>([]);
  const colRef = useEffect(() => {
    generateRandomString();
  }, []);

  const startDeleting = () => {
    setInterval(deleteCollection, 60000); // 60000 milliseconds = 60 seconds
  };

  useEffect(() => {
    if (topic !== "") {
      //publishToNtfy("test");
      signIn();
      startDeleting();
    }
  }, [topic]);

  const deleteCollection = async () => {
    try {
      //console.log("Deleting", topic);
      const querySnapshot = await getDocs(collection(db, topic));
      const deletePromises = querySnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deletePromises);
      console.log("Collection deleted successfully");
    } catch (error) {
      console.error("Error deleting collection:", error);
    }
  };

  function signIn() {
    signInAnonymously(auth)
      .then(() => {
        console.log("Signed in anonymously");
      })
      .catch((error) => {
        console.error("Error signing in anonymously:", error);
      });
  }

  const handleCopy = (data: any) => {
    const decrypted = decrypt(data, ivstring, keystring);

    navigator.clipboard.writeText(decrypted);
  };

  auth.onAuthStateChanged(function (user) {
    if (user) {
      initiateDB();
    }
  });

  const initiateDB = async () => {
    onSnapshot(collection(db, topic), (snapshot) => {
      const newDocuments: DocumentType[] = [];
      snapshot.docChanges().forEach((change) => {
        const newDoc = {
          id: change.doc.id,
          ...change.doc.data(),
        } as DocumentType;
        if (change.type === "added") {
          if (!docs.current.some((doc) => doc.id === newDoc.id)) {
            newDocuments.push(newDoc);
            docs.current.push(newDoc);
            setRows((prevRows) => [...prevRows, ...newDocuments]);
          }
        }
      });
    });
  };

  const generateRandomString = () => {
    const result = uuidv7();
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let key = "";
    let iv = "";
    const length = 32;
    const lengthIV = 16;
    for (let i = 0; i < length; i++) {
      key += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    for (let i = 0; i < lengthIV; i++) {
      iv += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setTopic(result);
    setIVString(iv);
    setKeyString(key);
    //console.log("IV:", iv);
    //console.log("Key:", key);
    setRandomString(result + "_" + iv + "_" + key);
  };

  const publishToNtfy = async (message: any) => {
    try {
      const response = await axios.post(`http://127.0.0.1/${topic}`, message, {
        headers: {
          "Content-Type": "text/plain",
        },
      });
      console.error("Done:", response.data);
      setSSE();
    } catch (error) {
      console.error("Error publishing message:", error);
    }
  };

  const setSSE = () => {
    const eventSource = new EventSource(`http://127.0.0.1/${topic}/sse`);

    eventSource.onopen = () => {
      setIsConnected(true);
      console.log("EventSource connection established");
    };

    eventSource.onmessage = (event) => {
      const message = JSON.parse(event.data);
      setMessages((prevMessages) => [...prevMessages, message]);
      console.log("Received message:", message);
    };

    eventSource.onerror = (error) => {
      console.error("EventSource error:", error);
      setIsConnected(false);
    };

    // Cleanup on component unmount
    return () => {
      eventSource.close();
      console.log("EventSource connection closed");
    };
  };

  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
      <Typography variant="h4" gutterBottom>
        Scan Qr Code on your device
      </Typography>
      <Box>
        <QRCode value={randomString} />
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Website</TableCell>
              <TableCell align="center">Username</TableCell>
              <TableCell align="center"> </TableCell>
              <TableCell align="center">Password</TableCell>
              <TableCell align="center"> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.website}
                </TableCell>
                <TableCell align="center">
                  <TextField
                    required
                    fullWidth
                    name="username"
                    type="text"
                    id="username"
                    disabled={true}
                    value={row.username}
                  />
                </TableCell>
                <TableCell align="center">
                  <Button onClick={() => handleCopy(row.username)}>
                    <FileCopyIcon />
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <TextField
                    required
                    fullWidth
                    name="password2"
                    type="password"
                    id="password2"
                    disabled={true}
                    autoComplete="new-password"
                    value={row.password}
                  />
                </TableCell>
                <TableCell align="center">
                  <Button onClick={() => handleCopy(row.password)}>
                    <FileCopyIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
export default QRCodePage;
