import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { updateUserName } from "../../services/appwrite/users";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { setPrefs, getPrefs } from "../../services/appwrite/authentication";

export default function UserProfile() {
  const [fullName, setFullName] = useState<string | string>("");
  const [email, setEmail] = useState<string | string>("");
  const [phone, setPhone] = useState<string | string>("");
  const [create, setCreate] = useState<boolean | boolean>(true);
  const [viewMode, setViewMode] = useState("default");
  const [language, setLanguage] = useState("");
  const [darkMode, setDarkMode] = useState(false);
  const [start, setStart] = useState(8);
  const [end, setEnd] = useState(18);
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  let org_id = localStorage.getItem("Busy-bee-orgasnisation-id");
  let user_id = localStorage.getItem("Busy-bee-user-id");

  const changeViewMode = (event: SelectChangeEvent) => {
    setViewMode(event.target.value as string);
    localStorage.setItem("BB-viewmode", event.target.value as string);
  };

  const changeStart = (event: SelectChangeEvent) => {
    //const start = event.target.value
    setStart(parseInt(event.target.value as string));
    localStorage.setItem("BB-start", event.target.value as string);
  };

  const changeEnd = (event: SelectChangeEvent) => {
    //const start = event.target.value
    setEnd(parseInt(event.target.value as string));
    localStorage.setItem("BB-end", event.target.value as string);
  };

  const setParameters = (data: any) => {
    setFullName(data.username);

    setPhone(data.number);
  };

  function getData() {}

  function setPrerences(data: any) {
    if (data !== null && data !== undefined) {
      setStart(data.startTime);
      setEnd(data.endTime);
      setViewMode(data.view);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const save = () => {};

  const saveSettings = () => {
    var prefs = {
      view: viewMode,
      startTime: start,
      endTime: end,
    };

    try {
      setPrefs(prefs)
        .then()
        .catch((e: any) => {
          setLoading(false);
          setError(e.message);
        });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      setError(e.message);
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" sx={{ mb: 4 }}>
        <Grid container spacing={2} sx={{ mt: 6 }}>
          <Grid item xs={12} md={6}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Typography variant="h6" gutterBottom>
                User Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="fullName"
                    name="fullName"
                    label="Full Name"
                    fullWidth
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    autoComplete="name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="phone_number"
                    name="phone_number"
                    label="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  onClick={save}
                  sx={{ mt: 3, ml: 1 }}
                  variant="contained"
                  color="secondary"
                >
                  Save
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        {loading ? (
          <CircularProgress />
        ) : (
          <Typography variant="body1">{error}</Typography>
        )}
      </Container>
    </React.Fragment>
  );
}
