import React, { Component, useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/home";
import SignIn from "./pages/sign-in";
import SignUp from "./pages/sign-up";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NavBarLayout from "./layout/navbar/online";
import OfflineNavBarLayout from "./layout/navbar/offline";
import ErrorPage from "./pages/error/error";
import Verification from "./pages/verify-user";
import UserProfile from "./pages/profile";
import ResetPassword from "./pages/passwordReset";
import ForgotPassword from "./pages/forgotPassword";
import {
  createTheme,
  ThemeProvider,
  styled,
  ThemeOptions,
} from "@mui/material/styles";
import Settings from "./pages/settings";
import theme from "./styling/theme";
import License from "./pages/license";
import QRCodePage from "./pages/qrCode";

function App() {
  const [log, setLog] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("gaurd-pass-user-id") !== null ||
      localStorage.getItem("gaurd-pass-user-id") !== ""
    ) {
      setLog(true);
    } else {
      setLog(false);
    }
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <NavBarLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/profile",
          element: <UserProfile />,
        },
        {
          path: "/settings",
          element: <Settings />,
        },
        {
          path: "/resetPassword",
          element: <ResetPassword />,
        },
        {
          path: "/forgotPassword",
          element: <ForgotPassword />,
        },
        {
          path: "/license",
          element: <License />,
        },
        {
          path: "/link",
          element: <QRCodePage />,
        },
      ],
    },
  ]);
  const offlineRouter = createBrowserRouter([
    {
      path: "/",
      element: <OfflineNavBarLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <SignIn />,
        },
        {
          path: "/register",
          element: <SignUp />,
        },
        {
          path: "/verify",
          element: <Verification />,
        },
        {
          path: "/resetPassword",
          element: <ResetPassword />,
        },
        {
          path: "/forgotPassword",
          element: <ForgotPassword />,
        },
        {
          path: "/license",
          element: <License />,
        },
        {
          path: "/link",
          element: <QRCodePage />,
        },
      ],
    },
  ]);
  return (
    <div className="App">
      {localStorage.getItem("gaurd-pass-user-id") ? (
        <ThemeProvider theme={theme}>
          <GoogleOAuthProvider clientId="507726094980-kveackrtc4n2qchtvm2ankvh5ktd4loj.apps.googleusercontent.com">
            <RouterProvider router={router} />
          </GoogleOAuthProvider>
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <RouterProvider router={offlineRouter} />
        </ThemeProvider>
      )}
    </div>
  );
}

export default App;
