import { Client, Account, Databases, Storage, Teams, Avatars } from "appwrite";
const PasswordsCollectionID = "660c39740030d6f5990a";
const CategoriesCollectionID = "660ecd8100141a7c8f5a";
const PurchaceCollectionID = "6623dde500134ce4fb16";

const databaseID = "660c37a3001ba905ee21"; // Your database ID
const client = new Client()
  .setEndpoint("https://appwrite.gaurdpass.com/v1") // We set the endpoint, change this if your using another endpoint URL.
  .setProject("660c353e001aebb2c233"); // Here replace 'ProjectID' with the project ID that you created in your appwrite installation.
const account = new Account(client);
const database = new Databases(client);
const storage = new Storage(client);
const teams = new Teams(client);
const avatars = new Avatars(client);

export {
  client,
  PasswordsCollectionID,
  CategoriesCollectionID,
  PurchaceCollectionID,
  databaseID,
  account,
  database,
  storage,
  avatars,
  teams,
}; // Finally export the client to be used in projects.
