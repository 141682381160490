import React, { useState } from "react";
// @mui material components
import Switch from "@mui/material/Switch";
import { verify } from "../../services/appwrite/authentication";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

const defaultTheme = createTheme();

const Verification = () => {
  let urlSearchParams = new URLSearchParams(window.location.search);
  let secret = urlSearchParams.get("secret");
  let userId = urlSearchParams.get("userId");
  let navigate = useNavigate();

  const completeVerification = () => {
    verify(userId, secret).then(next);
  };

  const next = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={completeVerification}
            sx={{ mt: 3, mb: 2 }}
            color="secondary"
          >
            verify
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Verification;
