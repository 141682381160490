import { createTheme } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#2A7FED",
      light: "#5498F0",
      dark: "#1D58A5",
      contrastText: "#ffff",
    },
    secondary: {
      main: "#2A7FED",
      light: "#5498F0",
      dark: "#1D58A5",
      contrastText: "#ffff",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
  },
});

export default theme;
