import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          calender: "Calender",
          team: "Team",
          profile: "Profile",
          home: "Home",
          confirm: "Confirm",
          cancel: "Cancel",
          save: "Save",
          addUser: "Add user",
          edit: "Edit",
          email: "Email Adress",
          password: "Password",
          forgot: "Forgot password?",
          signUpmessage: "Don't have an account? Sign Up",
          copyright: "Copyright",
          signIn: "Sign In",
          fullName: "Name and Surname",
          role: "Job Role",
          company: "Company",
          retype: "Retype Password",
          signUp: "Sign Up",
          accept: "I accept the terms of the licencing agreement.",
          already: "Already have an account? Sign in",
          description: "Description",
          adress: "Address",
          contact: "Contact",
          name: "Name",
          assignee: "Assignee",
        },
      },
      sw: {
        translation: {
          calender: "Kalenda",
          team: "Timu",
          profile: "Wasifu",
          home: "Nyumbani",
          confirm: "Thibitisha",
          cancel: "Ghairi",
          save: "Hifadhi",
          addUser: "Ongeza mtumiaji",
          edit: "Badilika",
          email: "Barua pepe",
          password: "Nenosiri",
          forgot: "Umesahau nywila?",
          signUpmessage: "Je, huna akaunti? ",
          copyright: "Copyright",
          signIn: "Weka sahihi",
          fullName: "Jina",
          role: "Kazi",
          company: "Kampuni",
          retype: "Nenosiri",
          signUp: "Jisajili",
          accept: "Ninakubali masharti ya makubaliano ya leseni.",
          already: "Tayari unayo akaunti.",
          description: "Maelezo",
          adress: "Anwani",
          contact: "Maelezo ya mawasiliano",
          name: "Jina",
          assignee: "Mfanyakazi",
        },
      },
    },
  });

export default i18n;
