import React, { Component, useState, useEffect } from "react";
import ResponsiveAppBar from "./componets";
import { Outlet } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"\n"}
      {"Copyright © "}
      <Link color="inherit" href="https://bzeeb.net">
        GaurdPass
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function NavBarLayout() {
  return (
    <>
      <ResponsiveAppBar />
      <div>
        <Outlet />
      </div>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </>
  );
}
export default NavBarLayout;
