import CryptoJS from "crypto-js";

function decrypt(encryptedData, ivString, keyString) {
  try {
    const keyBytes = CryptoJS.enc.Utf8.parse(keyString);
    const ivBytes = CryptoJS.enc.Utf8.parse(ivString);
    const decodedData = CryptoJS.enc.Base64.parse(encryptedData);

    //console.log("Key:", keyString, keyString.length);
    //console.log("IV:", ivString, ivString.length);
    //console.log("Encrypted Data:", encryptedData);

    const decrypted = CryptoJS.AES.decrypt(
      {
        ciphertext: decodedData,
      },
      keyBytes,
      {
        iv: ivBytes,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      }
    );
    //console.log("bytes:", decrypted);

    const decryptedText = CryptoJS.enc.Utf8.stringify(decrypted);
    //console.log("Text:", decryptedText);
    return decryptedText;
  } catch (error) {
    console.error("Error decrypting data:", error);
  }
}

function decrypt2(encryptedData, ivString, keyString) {
  try {
    const hash = CryptoJS.MD5(ivString).toString();
    const decodedKey = CryptoJS.enc.Base64.parse(keyString);
    const ivString2 = removeCharacters(hash, 16);
    const keyBytes = decodedKey;
    const ivBytes = CryptoJS.enc.Utf8.parse(ivString2);
    const decodedData = CryptoJS.enc.Base64.parse(encryptedData);

    //console.log("Key:", keyString, keyString.length);
    //console.log("IV:", ivString, ivString.length);
    //console.log("Encrypted Data:", decodedData);

    const decrypted = CryptoJS.AES.decrypt(
      {
        ciphertext: decodedData,
      },
      keyBytes,
      {
        iv: ivBytes,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      }
    );
    //console.log("bytes:", decrypted);

    const decryptedText = CryptoJS.enc.Utf8.stringify(decrypted);
    //console.log("Text:", decryptedText);
    return decryptedText;
  } catch (error) {
    console.error("Error decrypting data:", error);
  }
}

async function sha256ToBase64(input) {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const base64String = btoa(String.fromCharCode(...hashArray));
  return base64String;
}

const removeCharacters = (str) => {
  return str.length > 16 ? str.substring(16) : "";
};

export { decrypt, sha256ToBase64, decrypt2 };
