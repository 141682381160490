import { Account, Databases, ID } from "appwrite";
import { client, databaseID, account, database, avatars } from "./config";

const getUserData = async () => {
  try {
    return account.get();
  } catch (e) {
    throw e;
  }
};

const login = async (email, password) => {
  try {
    return account.createEmailSession(email, password);
  } catch (e) {
    throw e;
  }
};

const create = async (email, password, name) => {
  try {
    return account.create(ID.unique(), email, password, name);
  } catch (e) {
    throw e;
  }
};

const logout = async () => {
  try {
    return account.deleteSession("current");
  } catch (e) {
    throw e;
  }
};

const sendVerify = async () => {
  try {
    return account.createVerification("https://app.bzeeb.net/verify");
  } catch (e) {
    throw e;
  }
};

const verify = async (userId, secret) => {
  try {
    return account.updateVerification(userId, secret);
  } catch (e) {
    throw e;
  }
};

const accountRecover = async (email) => {
  try {
    return account.createRecovery(email, "https://app.bzeeb.net/resetPassword");
  } catch (e) {
    throw e;
  }
};

const confirmAccountRecover = async (userid, secret, password, password2) => {
  try {
    return account.updateRecovery(userid, secret, password, password2);
  } catch (e) {
    throw e;
  }
};

const initials = async () => {
  try {
    return avatars.getInitials();
  } catch (e) {
    throw e;
  }
};

const getPrefs = async () => {
  try {
    return account.getPrefs();
  } catch (e) {
    throw e;
  }
};

const setPrefs = async (prefs) => {
  try {
    return account.updatePrefs(prefs);
  } catch (e) {
    throw e;
  }
};

export {
  getUserData,
  login,
  logout,
  create,
  verify,
  sendVerify,
  initials,
  accountRecover,
  getPrefs,
  setPrefs,
  confirmAccountRecover,
};
