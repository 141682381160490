import React, { useState, useRef, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

import {
  create,
  sendVerify,
  login,
} from "../../services/appwrite/authentication";
import CircularProgress from "@mui/material/CircularProgress";
import { use } from "i18next";
import secureLocalStorage from "react-secure-storage";

export default function SignUp() {
  const [agreement, setAgreemnet] = useState(true);
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [team, setTeam] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const orderID = useRef("");
  const [errorText, setErrorText] = useState("");
  const [nameError, setNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [teamError, setTeamError] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [positionError, setPositionError] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = React.useState(false);
  const user_id = React.useRef("");

  let navigate = useNavigate();

  const handleSetAgremment = () => setAgreemnet(!agreement);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const register = () => {
    setLoading(true);
    clearErrors();

    if (!name) {
      setErrorText("Please enter your name");
      setNameError(true);
      setLoading(false);
      return;
    }

    if (!position || position.length < 3) {
      setErrorText("Please enter a valid Job Role");
      setPositionError(true);
      setLoading(false);
      return;
    }

    if (!email) {
      setErrorText("Email is required.");
      setEmailError(true);
      setLoading(false);
      return;
    }

    if (!team) {
      setErrorText("Please provide the name of a team or organisation name");
      setTeamError(true);
      setLoading(false);
      return;
    }

    /* if (emailPattern.test(email)) {
      setErrorText("Invalid email address!");
      setEmailError(true);
      return;
    }*/

    if (!password) {
      setErrorText("Password is required.");
      setPasswordError(true);
      setLoading(false);
      return;
    }

    if (password !== password2) {
      setErrorText("Passwords don't match.");
      setPasswordError(true);
      setLoading(false);
      return;
    }

    if (password.length < 8) {
      setErrorText("Password must be at least 8 characters long.");
      setPasswordError(true);
      setLoading(false);
      return;
    }

    if (!agreement) {
      setErrorText("Please Consent to our terms and conditions");
      setLoading(false);
      return;
    }
    setShowPayment(true);
  };

  function clearErrors() {
    setEmailError(false);
    setPasswordError(false);
    setPositionError(false);
    setNameError(false);
  }

  function Create(id: string) {
    //console.log(id);
  }

  function createOrganisation(id: string) {}

  function postlogin(id: string) {}

  // eslint-disable-next-line react/prop-types
  const ButtonWrapper = ({ type }: any) => {
    const [{ options }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
      dispatch({
        type: "resetOptions",
        value: {
          ...options,
          intent: "subscription",
        },
      });
    }, [type]);

    return (
      <PayPalButtons
        createSubscription={(data, actions) => {
          return actions.subscription
            .create({
              plan_id: "P-1FT03526SR561661MMWGEC4Q",
            })
            .then((orderId) => {
              // Your code here after create the order
              //setOrderID(orderId);

              return orderId;
            });
        }}
        onApprove={(data) => {
          orderID.current = data.subscriptionID!;
          //console.log(data.subscriptionID);
          setShowPayment(false);
          create(email, password, name).then((account) => next(account!.$id));

          return Promise.resolve();
        }}
        onError={(error) => {
          setShowPayment(false);
          //setErrorMessage(error.message);
        }}
        style={{
          label: "subscribe",
        }}
      />
    );
  };

  function next(id: string) {}
  function moveOn() {}
  function Nav() {
    setLoading(false);
    navigate("/");
    window.location.reload();
  }

  return (
    <PayPalScriptProvider
      options={{
        clientId:
          "ATcQckdfm5rtjgR8vgJMUyP2DsB7OVIG1pagw_YbA3Kt_3dVTBES9vJpeQD4-GpV2-SjHEFxY_a1Ux2z",
        components: "buttons",
        intent: "subscription",
        vault: true,
      }}
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <AssignmentIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name and Surname"
                  autoFocus
                  error={nameError}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="position"
                  label="Job Role"
                  name="position"
                  error={positionError}
                  onChange={(e) => setPosition(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={emailError}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="team"
                  label="Company name"
                  name="team"
                  error={teamError}
                  onChange={(e) => setTeam(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  error={passwordError}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password2"
                  label="Retype Password"
                  type="password"
                  id="password2"
                  autoComplete="new-password"
                  error={passwordError}
                  onChange={(e) => setPassword2(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Link href="/license" variant="body2">
                  View the license agreement
                </Link>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreement}
                      onChange={handleSetAgremment}
                      color="primary"
                    />
                  }
                  label="I accept the terms of the licencing agreement."
                />
              </Grid>
            </Grid>
            {showPayment ? (
              <ButtonWrapper type="subscription" />
            ) : (
              <Button
                variant="contained"
                size="large"
                fullWidth
                color="secondary"
                onClick={register}
              >
                sign up
              </Button>
            )}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
          {loading ? (
            <CircularProgress />
          ) : (
            <Typography variant="body1">{error}</Typography>
          )}
        </Box>
      </Container>
    </PayPalScriptProvider>
  );
}
