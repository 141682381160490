export default function License() {
  return (
    <div>
      <h1>B.Zee.B License Agreement</h1>
      <h2>1. DEFINITIONS</h2>
      <p>
        1.1 "Service" refers to the Licensor's cloud-based software service
        known as B.Zee.B.
        <br />
        1.2 "User" refers to an individual authorized by Licensee to use the
        Service.
        <br />
      </p>
      <h2>2. License Grant</h2>
      <p>
        2.1 Licensor hereby grants Licensee a non-exclusive, non-transferable
        license to access and use the Service for the duration of this
        Agreement, subject to the terms and conditions herein.
        <br />
        2.2 Licensee may permit its Users to use the Service, provided that each
        User complies with the terms of this Agreement and the number of
        additional users is limited to 5.
      </p>
      <h2>3. FEES AND PAYMENT</h2>
      <p>
        3.1 In consideration for the license granted, Licensee agrees to pay
        Licensor the fees of $10 per month.
        <br />
        3.2 All fees are non-refundable and are exclusive of any applicable
        taxes, which shall be the responsibility of Licensee.
      </p>
      <h2>4. TERM AND TERMINATION</h2>
      <p>
        4.1 The initial term of this Agreement is idefinate and shall continue
        until terminated by either party
        <br />
        4.2 Either party may terminate this Agreement for cause with written
        notice if the other party breaches a material term and fails to cure
        such breach within 60 days of receiving written notice (physical or
        electronic).
      </p>
      <h2>5. OWNERSHIP AND INTELLECTUAL PROPERTY</h2>
      <p>
        5.1 Licensor retains all rights, title, and interest in and to the
        Service, including all intellectual property rights.
        <br />
        5.2 Licensee shall not reverse engineer, decompile, or disassemble the
        Service or attempt to derive the source code.
      </p>

      <h2>6. CONFIDENTIALITY</h2>
      <p>
        6.1 Both parties agree to keep confidential any non-public information
        received from the other party and to take all reasonable precautions to
        prevent unauthorized disclosure.
      </p>

      <h2>7. LIMITATION OF LIABILITY</h2>
      <p>
        7.1 To the extent permitted by law, Licensor shall not be liable for any
        indirect, incidental, consequential, or punitive damages arising out of
        or in connection with this Agreement or use of the provided software.
      </p>
    </div>
  );
}
