import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  getPrefs,
  confirmAccountRecover,
} from "../../services/appwrite/authentication";
import CircularProgress from "@mui/material/CircularProgress";

export default function ResetPassword() {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  let urlSearchParams = new URLSearchParams(window.location.search);
  let navigate = useNavigate();
  let secret = urlSearchParams.get("secret");
  let userId = urlSearchParams.get("userId");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let password = data.get("password");
    let password2 = data.get("password2");

    if (password !== password2) {
      setError("Passwords do not match");
      console.log(password);
      console.log(password2);
      setLoading(false);
      return;
    }

    try {
      confirmAccountRecover(userId, secret, password, password2)
        .then((result) => moveOn())
        .catch((e: any) => {
          setLoading(false);
          setError(e.message);
        });
    } catch (e: any) {
      setLoading(false);
      setError(e.message);
    }
  };

  const moveOn = () => {
    navigate("/");
    window.location.reload();
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Enter the new password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password2"
            label="Retype Password"
            type="password"
            id="password2"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="secondary"
          >
            Reset Password
          </Button>
        </Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <Typography variant="body1">{error}</Typography>
        )}
      </Box>
    </Container>
  );
}
