import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  getPrefs,
  accountRecover,
} from "../../services/appwrite/authentication";
import CircularProgress from "@mui/material/CircularProgress";

export default function ForgotPassword() {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  let navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let email = data.get("email");

    try {
      accountRecover(email)
        .then((result) => moveOn())
        .catch((e: any) => {
          setLoading(false);
          setError(e.message);
        });
    } catch (e: any) {
      setLoading(false);
      setError(e.message);
    }
  };

  const moveOn = () => {
    setLoading(false);
    setError("Check your email for the reset link");
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <AlternateEmailIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Enter Your Email Adress
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="secondary"
          >
            Send
          </Button>
        </Box>
        <Link href="/" variant="body2">
          {"Login"}
        </Link>
        {loading ? (
          <CircularProgress />
        ) : (
          <Typography variant="body1">{error}</Typography>
        )}
      </Box>
    </Container>
  );
}
