import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";

const language = ["default", "tabs"];

export default function Settings() {
  const [viewMode, setViewMode] = useState("default");
  const [language, setLanguage] = useState("");
  const [darkMode, setDarkMode] = useState(false);
  const [start, setStart] = useState(8);
  const [end, setEnd] = useState(18);

  const changeViewMode = (event: SelectChangeEvent) => {
    setViewMode(event.target.value as string);
  };

  const changeStart = (event: SelectChangeEvent) => {
    //const start = event.target.value
    setStart(parseInt(event.target.value as string));
  };

  const changeEnd = (event: SelectChangeEvent) => {
    //const start = event.target.value
    setEnd(parseInt(event.target.value as string));
  };

  return <Container component="main"></Container>;
}
