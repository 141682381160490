import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { login } from "../../services/appwrite/authentication";
import CircularProgress from "@mui/material/CircularProgress";
import icon from "../../images/icon.png";
import { setPrefs, getUserData } from "../../services/appwrite/authentication";
import secureLocalStorage from "react-secure-storage";

var encode = require("hashcode").hashCode;

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SignIn() {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [Resource, setResource] = React.useState<any[] | any>();
  let navigate = useNavigate();
  const memebershipID = React.useRef("");
  const email = React.useRef("");
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let email = data.get("email");
    let password = data.get("password");

    try {
      login(email, password)
        .then((account) => postlogin(account))
        .catch((e: any) => {
          setLoading(false);
          setError(e.message);
        });
    } catch (e: any) {
      setLoading(false);
      setError(e.message);
    }
  };

  function postlogin(data: any) {
    localStorage.setItem("gaurd-pass-user-id", data.userId);

    try {
      getUserData()
        .then((data) => {
          localStorage.setItem("name", data.email.substring(0, 3));
          localStorage.setItem("USERNAME", data.email);
          secureLocalStorage.setItem("master-password", "");
          window.location.reload();
        })
        .catch((e: any) => {
          setLoading(false);
          setError(e.message);
        });
    } catch (e: any) {
      setLoading(false);
      setError(e.message);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <img src={icon} alt={"logo"} loading="lazy" width="40" height="40" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="secondary"
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgotPassword" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <Typography variant="body1">{error}</Typography>
        )}
      </Box>
    </Container>
  );
}
