import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCRzNYc1hds61clW9SZZgB9HJbKu1LsPpA",
  authDomain: "vault2-2a2fc.firebaseapp.com",
  projectId: "vault2-2a2fc",
  storageBucket: "vault2-2a2fc.appspot.com",
  messagingSenderId: "288679861588",
  appId: "1:288679861588:web:ce5dccae719dd5f64b3cc1",
  measurementId: "G-WL0DB5Q74L",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
