/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-mixed-operators */
import React, { Fragment, useRef, useState, useEffect } from "react";
import { Typography } from "@mui/material";
import {
  SchedulerRef,
  EventActions,
  ProcessedEvent,
  DayHours,
} from "@aldabil/react-scheduler/types";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { useTranslation, Trans } from "react-i18next";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  createTheme,
  ThemeProvider,
  styled,
  ThemeOptions,
} from "@mui/material/styles";
import Container from "@mui/material/Container";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import EditIcon from "@mui/icons-material/Edit";
import {
  getPasswordDocument,
  getPasswordDocuments,
  search,
} from "../../services/appwrite/passwords";
import { decrypt, decrypt2 } from "../../services/aes/aes";
import secureLocalStorage from "react-secure-storage";
import { sha256ToBase64 } from "../../services/aes/aes";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Home() {
  type Row = {
    title: string;
    website: string;
    username: string;
    password: string;
    category: string;
    notes: string;
  };

  const { t, i18n } = useTranslation();
  const [rows, setRows] = React.useState<Row[]>([]);
  const [selectedRow, setSelectedRow] = React.useState<Row | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [masterpassword, setMasterPassword] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState((page - 1) * 20);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const masterPasswordRef = useRef("");

  const saveMasterPassword = async () => {
    setLoading(true);
    setOpen(false);

    if (!masterpassword) {
      setLoading(false);
      return;
    } else {
      try {
        let hash = await sha256ToBase64(masterpassword);
        masterPasswordRef.current = hash;
        setLoading(false);
      } catch (e: any) {
        setError(e);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    try {
      getPasswordDocuments(offset)
        .then((data) => setRows(data.documents as unknown as Row[])) // Cast the data.documents to Document[]
        .catch((e: any) => {
          setLoading(false);
          setError(e.message);
        });
    } catch (e: any) {
      setLoading(false);
      setError(e.message);
    }
  }

  function pevNext(off: any) {
    try {
      getPasswordDocuments(off)
        .then((data) => setRows(data.documents as unknown as Row[])) // Cast the data.documents to Document[]
        .catch((e: any) => {
          setLoading(false);
          setError(e.message);
        });
    } catch (e: any) {
      setLoading(false);
      setError(e.message);
    }
  }
  function titleSearch(term: any) {
    try {
      search(term)
        .then((data) => setRows(data.documents as unknown as Row[])) // Cast the data.documents to Document[]
        .catch((e: any) => {
          setLoading(false);
          setError(e.message);
        });
    } catch (e: any) {
      setLoading(false);
      setError(e.message);
    }
  }

  const handleCopy = (data: any) => {
    if (masterPasswordRef.current.length > 0) {
      //console.log("Master:", masterPasswordRef.current);
      const username = localStorage.getItem("USERNAME");
      const decrypted = decrypt2(data, username, masterPasswordRef.current);
      //console.log("Decrypted:", decrypted);
      if (decrypted.length > 0) {
        navigator.clipboard.writeText(decrypted);
      } else {
        alert("Error decrypting password");
      }
    } else {
      setOpen(true);
    }
  };

  const handleEdit = (data: any) => {
    // You can set up a modal or form to edit this data
    setOpen(true);
    setSelectedRow(data);
    console.log("Edit:", data);
  };

  const handleNext = () => {
    if (rows.length < 10) return;
    setPage((prevPage) => prevPage + 1);
    const off = (page - 1) * 10;
    setOffset(off);
    pevNext(off);
  };

  const handlePrevious = () => {
    if (page === 1) return;
    setPage((prevPage) => prevPage - 1);
    const off = (page - 1) * 10;
    setOffset(off);
    pevNext(off);
  };

  return (
    <Container component="main" sx={{ p: 6 }}>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="search"
          label="Search"
          onChange={(e) => titleSearch(e.target.value)}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Title</TableCell>
              <TableCell align="center">Username</TableCell>
              <TableCell align="center"> </TableCell>
              <TableCell align="center">Password</TableCell>
              <TableCell align="center"> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell align="center">{row.username}</TableCell>
                <TableCell align="center">
                  <Button onClick={() => handleCopy(row.username)}>
                    <FileCopyIcon />
                  </Button>
                </TableCell>
                <TableCell align="center">{row.password}</TableCell>
                {/*<TableCell align="center">
                  <Button onClick={() => handleEdit(row)}>
                    <EditIcon />
                  </Button>
            </TableCell>*/}
                <TableCell align="center">
                  <Button onClick={() => handleCopy(row.password)}>
                    <FileCopyIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div
          style={{ display: "flex", justifyContent: "flex-end", padding: 8 }}
        >
          <Button
            disabled={page === 1}
            startIcon={<ArrowBackIosIcon />}
            onClick={handlePrevious}
          >
            Previous
          </Button>
          <Button endIcon={<ArrowForwardIosIcon />} onClick={handleNext}>
            Next
          </Button>
        </div>
      </TableContainer>
      {loading ? (
        <CircularProgress />
      ) : (
        <Typography variant="body1">{error}</Typography>
      )}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Enter your master password
              </Typography>
              <Box component="form" noValidate sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      onChange={(e) => setMasterPassword(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  color="secondary"
                  onClick={saveMasterPassword}
                >
                  save
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    </Container>
  );
}
