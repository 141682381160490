import { ID } from "appwrite";
import { PasswordsCollectionID, databaseID, database } from "./config";
import { Permission, Role, Query } from "appwrite";

const createPasswordDocument = async (passwordData, id) => {
  try {
    return database.createDocument(
      databaseID,
      PasswordsCollectionID,
      ID.unique(),
      passwordData
    );
  } catch (e) {
    throw e;
  }
};

const getPasswordDocuments = async (offset) => {
  try {
    return database.listDocuments(databaseID, PasswordsCollectionID, [
      Query.limit(10),
      Query.offset(offset),
    ]);
  } catch (e) {
    throw e;
  }
};

const search = async (term) => {
  try {
    return database.listDocuments(databaseID, PasswordsCollectionID, [
      Query.limit(10),
      Query.startsWith("title", term),
    ]);
  } catch (e) {
    throw e;
  }
};

const updatePasswordDocument = async (documentID, passwordData) => {
  try {
    return database.updateDocument(
      databaseID,
      PasswordsCollectionID,
      documentID,
      passwordData
    );
  } catch (e) {
    throw e;
  }
};

const deletePassword = async (documentID) => {
  try {
    return database.deleteDocument(
      databaseID,
      PasswordsCollectionID,
      documentID
    );
  } catch (e) {
    throw e;
  }
};

const getPasswordDocument = async (documentID) => {
  try {
    return database.getDocument(databaseID, PasswordsCollectionID, documentID);
  } catch (e) {
    throw e;
  }
};

export {
  createPasswordDocument,
  updatePasswordDocument,
  deletePassword,
  getPasswordDocument,
  search,
  getPasswordDocuments,
};
